const ECHARTS_THEME = {
	"color": [
		"#0abefa",
		"#34c189",
		"#f47719",
		"#6877f6",
		"#73c0de",
		"#3ba272",
		"#fc8452",
		"#9a60b4",
		"#ea7ccc"
	],
	// "backgroundColor": "#000b38",
	"textStyle": {},
	"title": {
		"textStyle": {
			"color": "#0c72f8"
		},
		"subtextStyle": {
			"color": "#0c72f8"
		}
	},
	"line": {
		"itemStyle": {
			"borderWidth": "1"
		},
		"lineStyle": {
			// "width": "2"
		},
		"symbolSize": "4",
		"symbol": "emptyCircle",
		"smooth": true
	},
	"radar": {
		"itemStyle": {
			"borderWidth": "1"
		},
		"lineStyle": {
			"width": "2"
		},
		"symbolSize": "4",
		"symbol": "emptyCircle",
		"smooth": true
	},
	"bar": {
		"itemStyle": {
			"barBorderWidth": 0,
			"barBorderColor": "#ccc"
		}
	},
	"pie": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"scatter": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"boxplot": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"parallel": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"sankey": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"funnel": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"gauge": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		}
	},
	"candlestick": {
		"itemStyle": {
			"color": "#eb5454",
			"color0": "#47b262",
			"borderColor": "#eb5454",
			"borderColor0": "#47b262",
			"borderWidth": 1
		}
	},
	"graph": {
		"itemStyle": {
			"borderWidth": 0,
			"borderColor": "#ccc"
		},
		"lineStyle": {
			"width": 1,
			"color": "#aaa"
		},
		"symbolSize": "4",
		"symbol": "emptyCircle",
		"smooth": true,
		"color": [
			"#0abefa",
			"#34c189",
			"#f47719",
			"#6877f6",
			"#73c0de",
			"#3ba272",
			"#fc8452",
			"#9a60b4",
			"#ea7ccc"
		],
		"label": {
			"color": "#ffffff"
		}
	},
	"map": {
		"itemStyle": {
			"normal": {
				"areaColor": "#eee",
				"borderColor": "#444",
				"borderWidth": 0.5
			},
			"emphasis": {
				"areaColor": "rgba(255,215,0,0.8)",
				"borderColor": "#444",
				"borderWidth": 1
			}
		},
		"label": {
			"normal": {
				"textStyle": {
					"color": "#000"
				}
			},
			"emphasis": {
				"textStyle": {
					"color": "rgb(100,0,0)"
				}
			}
		}
	},
	"geo": {
		"itemStyle": {
			"normal": {
				"areaColor": "#eee",
				"borderColor": "#444",
				"borderWidth": 0.5
			},
			"emphasis": {
				"areaColor": "rgba(255,215,0,0.8)",
				"borderColor": "#444",
				"borderWidth": 1
			}
		},
		"label": {
			"normal": {
				"textStyle": {
					"color": "#000"
				}
			},
			"emphasis": {
				"textStyle": {
					"color": "rgb(100,0,0)"
				}
			}
		}
	},
	"categoryAxis": {
		"axisLine": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisTick": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisLabel": {
			"show": true,
			"textStyle": {
				"color": "#a4a5ab"
			}
		},
		"splitLine": {
			"show": false,
			"lineStyle": {
				"color": [
					"#E0E6F1"
				]
			}
		},
		"splitArea": {
			"show": false,
			"areaStyle": {
				"color": [
					"rgba(250,250,250,0.2)",
					"rgba(210,219,238,0.2)"
				]
			}
		}
	},
	"valueAxis": {
		"axisLine": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisTick": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisLabel": {
			"show": true,
			"textStyle": {
				"color": "#a4a5ab"
			}
		},
		"splitLine": {
			"show": false,
			"lineStyle": {
				"color": [
					"#E0E6F1"
				]
			}
		},
		"splitArea": {
			"show": false,
			"areaStyle": {
				"color": [
					"rgba(250,250,250,0.2)",
					"rgba(210,219,238,0.2)"
				]
			}
		}
	},
	"logAxis": {
		"axisLine": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisTick": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisLabel": {
			"show": true,
			"textStyle": {
				"color": "#a4a5ab"
			}
		},
		"splitLine": {
			"show": false,
			"lineStyle": {
				"color": [
					"#E0E6F1"
				]
			}
		},
		"splitArea": {
			"show": false,
			"areaStyle": {
				"color": [
					"rgba(250,250,250,0.2)",
					"rgba(210,219,238,0.2)"
				]
			}
		}
	},
	"timeAxis": {
		"axisLine": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisTick": {
			"show": true,
			"lineStyle": {
				"color": "#a4a5ab"
			}
		},
		"axisLabel": {
			"show": true,
			"textStyle": {
				"color": "#a4a5ab"
			}
		},
		"splitLine": {
			"show": false,
			"lineStyle": {
				"color": [
					"#E0E6F1"
				]
			}
		},
		"splitArea": {
			"show": false,
			"areaStyle": {
				"color": [
					"rgba(250,250,250,0.2)",
					"rgba(210,219,238,0.2)"
				]
			}
		}
	},
	"toolbox": {
		"iconStyle": {
			"normal": {
				"borderColor": "#ffffff"
			},
			"emphasis": {
				"borderColor": "#ffffff"
			}
		}
	},
	"legend": {
		"textStyle": {
			"color": "#ffffff"
		}
	},
	"tooltip": {
		"axisPointer": {
			"lineStyle": {
				"color": "#ffffff",
				"width": "1"
			},
			"crossStyle": {
				"color": "#ffffff",
				"width": "1"
			}
		}
	},
	"timeline": {
		"lineStyle": {
			"color": "#a4a5ab",
			"width": 2
		},
		"itemStyle": {
			"normal": {
				"color": "#A4B1D7",
				"borderWidth": 1
			},
			"emphasis": {
				"color": "#FFF"
			}
		},
		"controlStyle": {
			"normal": {
				"color": "#a4a5ab",
				"borderColor": "#a4a5ab",
				"borderWidth": 1
			},
			"emphasis": {
				"color": "#a4a5ab",
				"borderColor": "#a4a5ab",
				"borderWidth": 1
			}
		},
		"checkpointStyle": {
			"color": "#316bf3",
			"borderColor": "#ffffff"
		},
		"label": {
			"normal": {
				"textStyle": {
					"color": "#a4a5ab"
				}
			},
			"emphasis": {
				"textStyle": {
					"color": "#a4a5ab"
				}
			}
		}
	},
	"visualMap": {
		"color": [
			"#bf444c",
			"#d88273",
			"#f6efa6"
		]
	},
	"dataZoom": {
		"handleSize": "undefined%",
		"textStyle": {}
	},
	"markPoint": {
		"label": {
			"color": "#ffffff"
		},
		"emphasis": {
			"label": {
				"color": "#ffffff"
			}
		}
	}
};

const getEchartsTheme = () => {
	return ECHARTS_THEME;
}

export default {
	getEchartsTheme
}
