<!-- 库容柱状渐变颜色统计 -->
<template>
  <div id="chartGradient">
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Array
    }
  },
  watch: {
    res: function () {
      this.echartInit()
    },
  },
  methods: {
    echartInit() {
      let data5 = this.echarts.init(document.getElementById('chartGradient'), 'echarts_darken');
      const labelOption = {
        show: true,
        position: 'top',
        distance: 20,
        align: 'center',
        verticalAlign: 'middle',
        rotate: 0,
        formatter: '{b}',
        fontSize: 12,
        color: "#ffffff",
        rich: {
          value: {}
        }
      };
      let option = {
        color: ['#2E6BFA', '#47A8B4'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        grid: {
          top: 30,
          left: 35,
          right: 25,
          bottom: 5
        },
        xAxis: {
          type: 'category',
          //data: ['01:00', '03:00', '05:00', '07:00', '09:00', '11:00', '13:00', '15:00', '17:00','19:00'],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          axisTick: {
            show: false // 不显示坐标轴刻度线
          },
        },
        yAxis: [{
          type: 'value',
          name: '库容(万方)',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#182232',
              width: 1,
              type: 'solid'
            }
          }
        }],
        series: {
          name: "库容",
          type: 'bar',
          label: labelOption,
          //data: [47, 32, 20, 16, 21, 12, 5, 16, 32, 40],
          itemStyle: {
            color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#0AC7FB'
            }, {
              offset: 1,
              color: '#95BE88'
            }])
          },
        }
      };

      let titles = this.res.map(item => item.title)
      let storage = this.res.map(item => parseFloat(item.storage))

      option.xAxis.data = titles;
      option.series.data = storage;
      data5.setOption(option);
      window.addEventListener("resize", function () {
        data5.resize();
      });

    }
  },
}
</script>

<style lang="scss">
#chartWindSpeed {
  width: 100%;
  height: 100%;
}
</style>
