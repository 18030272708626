<!-- 风速设备折线图统计 -->
<template>
  <div id="chartWindSpeed">
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object
    }
  },
  watch: {
    res: function () {
      this.res.wind.forEach(item => {
        item.name = item.title
        item.type = 'line';
      });
      this.echartInit()
    },
  },
  methods: {
    echartInit() {
      let windSpeed = this.echarts.init(document.getElementById('chartWindSpeed'), 'echarts_darken');
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true
        },
        grid: {
          top: 10,
          left: 30,
          right: 30,
          bottom: 20
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', "12:00", '14:00', '16:00', '18:00', '20:00', '22:00', "24:00",
          ]
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '平塘镇沙子河',
          type: 'line',
          data: [56.5, 82.1, 88.7, 0, 53.4, 100]
        },
        {
          name: '东江河池洞段',
          type: 'line',
          data: [51.1, 51.4, 55.1, 53.3, 73.8, 68.7]
        },
        {
          name: '朱砂镇朱沙河',
          type: 'line',
          data: [40.1, 62.2, 69.5, 36.4, 45.2, 32.5]
        },
        {
          name: '黄华江钱排河上游',
          type: 'line',
          data: [25.2, 37.1, 41.2, 18, 33.9, 49.1]
        },
        {
          name: '杨梅河',
          type: 'line',
          data: [45.2, 17.1, 21.2, 10, 23.9, 21.1]
        }
        ]
      };
      let wind = this.res.wind;
      option.series = wind;
      option.xAxis.data = this.res.windTime;

      windSpeed.setOption(option);
      window.onresize = windSpeed.resize;
      // window.addEventListener("resize", function () {
      //   windSpeed.resize();
      // });
    }
  },
}
</script>

<style lang="scss">
#chartWindSpeed {
  width: 100%;
  height: 100%;
}
</style>
