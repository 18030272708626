<!-- 水位柱状统计 -->
<template>
  <div id="chartWater">
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Array
    }
  },
  watch: {
    res: function () {
      this.echartInit()
    },
  },
  methods: {
    echartInit() {
      let data4 = this.echarts.init(document.getElementById('chartWater'), 'echarts_darken');
      const labelOption = {
        show: true,
        position: 'top',
        distance: 20,
        align: 'center',
        verticalAlign: 'middle',
        rotate: 0,
        formatter: '{b}',
        fontSize: 12,
        color: '#ffffff',
        rich: {
          value: {}
        }
      };
      let option = {
        color: ['#2E6BFA', '#47A8B4'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        //legend: {// data: ['蒸发量', '降水量', '平均温度']},
        grid: {
          top: 50,
          left: 35,
          right: 25,
          bottom: 5
        },
        xAxis: {
          type: 'category',
          //data: ['平塘镇沙子河', '东江河池洞段', '朱砂镇朱沙河', '黄华江钱排河上游', '杨梅河', '白龙河', '西镇水库'],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          axisTick: {
            show: false // 不显示坐标轴刻度线
          },
        },
        yAxis: {
          type: 'value',
          name: '水位(米)',
          //interval: 15,
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#182232',
              width: 1,
              type: 'solid'
            }
          }
        }
        ,
        series: {
          name: '水位',
          type: 'bar',
          label: labelOption,
          //data: [140, 45, 35, 37, 20, 23, 30],
          itemStyle: {
            color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#108AF0'
            }, {
              offset: 1,
              color: '#4061E8'
            }])
          },
        }

      };


      let items = this.res.filter(item => item.title.indexOf("水库") != -1);
      let titles = items.map(item => item.title)
      let waters = items.map(item => parseFloat(item.water))
      option.xAxis.data = titles;
      option.series.data = waters;
      data4.setOption(option);

      window.addEventListener("resize", function () {
        data4.resize();
      });
    }
  },
}
</script>

<style lang="scss">
#chartWindSpeed {
  width: 100%;
  height: 100%;
}
</style>
